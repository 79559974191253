// <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15">

import React from 'react';
import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
  <React.Fragment>
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M1.828 4.643c.24.17.967.674 2.178 1.514 1.212.84 2.14 1.486 2.784 1.939l.452.324c.23.167.422.301.574.404.152.103.336.218.552.345.217.128.42.224.612.287.19.064.368.096.53.096h.022c.163 0 .34-.032.532-.096a3.21 3.21 0 0 0 .61-.287c.217-.127.4-.242.553-.345a22 22 0 0 0 .574-.404c.23-.166.38-.274.452-.324l4.973-3.454c.517-.36.95-.797 1.296-1.307.348-.51.521-1.045.521-1.604 0-.468-.168-.868-.505-1.201a1.642 1.642 0 0 0-1.195-.5H1.7C1.155.03.735.216.441.584.147.951 0 1.412 0 1.964c0 .447.195.93.584 1.451.39.52.805.93 1.244 1.228z" />
    <path d="M17.98 5.78c-2.323 1.572-4.087 2.794-5.292 3.666-.403.297-.731.53-.983.696a6.066 6.066 0 0 1-1.004.51c-.418.173-.807.26-1.169.26H9.511c-.361 0-.751-.087-1.169-.26a6.069 6.069 0 0 1-1.004-.51c-.252-.167-.58-.399-.983-.696-.957-.702-2.717-1.924-5.282-3.667A5.629 5.629 0 0 1 0 4.855v8.437c0 .468.166.868.5 1.201.332.333.733.5 1.2.5h15.643c.467 0 .868-.167 1.2-.5.334-.333.5-.733.5-1.2V4.854a5.86 5.86 0 0 1-1.063.924z" />
  </React.Fragment>,
  'Envelope',
);
