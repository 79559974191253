import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';

import cardStyle from '../../../assets/jss/components/cardStyle';

function Card({ ...props }) {
  const {
    classes,
    className,
    children,
    outlined,
    plain,
    raised,
    background,
    color,
    login,
    ...rest
  } = props;
  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardRaised]: raised,
    [classes.cardBackground]: background,
    [classes[color]]: color,
    [classes.cardLogin]: login,
    [classes.cardOutlined]: outlined,
    [className]: className !== undefined,
  });
  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
}

Card.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  plain: PropTypes.bool,
  raised: PropTypes.bool,
  background: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  login: PropTypes.bool,
  outlined: PropTypes.bool,
};

export default withStyles(cardStyle)(Card);
