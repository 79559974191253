import { cardTitle } from '../shared';

const authPageStyle = theme => ({
  container: {
    backgroundImage: "url('/static/login_bg.jpg')",
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    borderRadius: 10,
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
  },
  cardTitle: {
    ...cardTitle,
    color: '#FFFFFF',
    fontWeight: 700,
    lineHeight: 0.8,
    paddingBottom: 5,
    fontSize: 30,
  },
  textCenter: {
    textAlign: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center !important',
  },
  customButtonClass: {
    '&,&:focus,&:hover': {
      color: '#FFFFFF',
    },
    marginLeft: '5px',
    marginRight: '5px',
  },
  inputAdornment: {
    marginRight: '18px',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  cardHidden: {
    opacity: '0',
    transform: 'translate3d(0, -60px, 0)',
  },
  cardHeader: {
    height: 147,
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginBtn: {
    width: 250,
    height: 44,
    borderRadius: 25,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    transform: 'translate3d(0, 0, 0)',
    transition: 'all 300ms linear',
  },
  recoveryBtn: {
    width: 350,
    height: 54,
    borderRadius: 25,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    transform: 'translate3d(0, 0, 0)',
    transition: 'all 300ms linear',
  },
  socialLine: {
    padding: '0.9375rem 0',
  },
  forgotPasswordBtn: {
    textTransform: 'capitalize',
    fontWeight: 700,
  },
});

export default authPageStyle;
