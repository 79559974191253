// <svg xmlns="http://www.w3.org/2000/svg" width="18" height="25" viewBox="0 0 18 25">

import React from 'react';
import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
  <React.Fragment>
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M14.584 12.256l.178-.178a4.787 4.787 0 0 0 1.235-4.622A4.73 4.73 0 0 0 12.9 4.161l-.217-.735C12.03 1.313 10.679 0 9.158 0c-1.5 0-2.844 1.283-3.496 3.343a8.142 8.142 0 0 0-.17.614 4.79 4.79 0 0 0-4.16 4.739 4.738 4.738 0 0 0 1.4 3.382l.173.178-.882 3.314.865 2.217-1.691 1.309.839 1.878-1.878 1.543-.135.51 1.726 1.964 2.474-.839 2.93-10.782.24-.066A4.78 4.78 0 0 0 6.4 3.926c.03-.104.057-.213.087-.317.53-1.665 1.578-2.74 2.67-2.74 1.108 0 2.165 1.105 2.69 2.81l.08.264a4.795 4.795 0 0 0-2.323.314 5.705 5.705 0 0 1 1.727 2.269 2.167 2.167 0 0 1 1.93 3.252 2.153 2.153 0 0 1-1.887 1.092c-.013 0-.03-.005-.043-.005a5.695 5.695 0 0 1-1.727 2.27c.176.072.356.133.54.182l.243.07.883 3.309 1.86 1.482-.808 1.978 1.665 1.214-.852 2.273.135.51 2.474.838 1.726-1.965-2.887-10.77zM2.392 24.17l-.834-.226 2.926-10.77.834.227-2.926 10.77zm3.726-13.3a2.331 2.331 0 0 1-.569-.073A2.182 2.182 0 0 1 4.014 8.13 2.173 2.173 0 0 1 5.266 6.7c.009.112.013.22.022.343a9.671 9.671 0 0 0 1.487 3.721 2.224 2.224 0 0 1-.657.105zm.392-4.308a.702.702 0 0 1 .121.026l.048.009A2.182 2.182 0 0 1 8.214 9.26c-.114.419-.35.794-.678 1.078a8.797 8.797 0 0 1-1.378-3.378c-.014-.152-.026-.296-.03-.435.128-.001.256.01.382.035zm8.843 17.56L12.475 13.37l.843-.227 2.879 10.753-.844.226z" />
  </React.Fragment>,
  'Keys',
);
